@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

/* GENERAL */

.frame {
  height: 100vh;
}

body {
  font-family: "DM Mono", -apple-system, "Helvetica Neue", serif;
  font-size: calc(7px + 1vw);
}

.light {
  background: #e1fef7;
  color: #001611;
}

.dark {
  background: #034031;
  color: white;
}

.lightText {
  color: #e1fef7;
}

.greenText {
  color: #034031;
}

.headingOne {
  font-size: calc(10px + 1.5vw);
  letter-spacing: normal;
  font-weight: 500;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
}

.headingTwo {
  font-size: calc(32px + 1.5vw);
  letter-spacing: normal;
  font-weight: 500;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  margin-bottom: 30px;
}

.center {
  display: grid;
  justify-items: center;
}

/* buttons */

.lightBtn {
  background-color: #d3ede7;
  color: #034031;
}

.lightBtn:hover {
  background-color: #bbdad2;
}

.greenBtn {
  background-color: #007054;
  color: #e1fef7;
}

.greenBtn:hover {
  background-color: #005843;
}

.button {
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: calc(10px + 1.2vw);
  font-weight: 500;
  padding: 4px 30px;
  border-radius: 25px;
  border: none;
  margin-top: 30px;
}

.backBtn {
  position: fixed;
  top: 0;
  left: 10px;
  background-color: transparent;
  font-size: 40px;
  border: none;
}

/* TITLE */
.title {
  font-size: calc(36px + 1.5vw);
  font-weight: 600;
  color: #034031;
  margin-bottom: 20px;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
}

.titleContainer {
  display: grid;
  grid-template-columns: 60% auto;
  align-items: center;
  padding-left: 10vw;
  gap: 50px;
}

#illo {
  width: 100%;
}

@media (max-width: 750px) {
  #illo {
    display: none;
  }

  .titleContainer {
    display: grid;
    grid-template-columns: auto 0;
    padding: 0 10vw;
    gap: 0;
  }
}

/* INTRODUCTION */

.long {
  height: fit-content;
  padding: 10vw;
}

hr {
  height: 1px;
  border-width: 0;
  background-color: white;
}

.dropdown {
  color: #ffcfa3;
  font-size: 25px;
}

.dropdown:hover {
  cursor: pointer;
}

.headingThree {
  margin: 40px 0;
  text-align: center;
  font-size: calc(12px + 1.5vw);
  letter-spacing: normal;
  font-weight: 500;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
}

.password {
  display: block;
  width: 90%;
  height: 10vw;
  margin-left: auto;
  margin-right: auto;
}

/* custom scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e1fef7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007054;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #005843;
}

/* TRANSITION */
.chefFrame {
  padding: 0 10vw;
  display: grid;
  align-items: center;

  /* image */
  background-image: url("chef.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.fill {
  background: #e1fef7;
  width: fit-content;
}

.chefText {
  width: 40vw;
}

@media (max-width: 610px) {
  .chefFrame {
    background-image: none;
  }

  .chefText {
    width: 100%;
  }
}

/* INTERACTIVE */

.interactiveTitle {
  color: #e1fef7;
  font-size: calc(12px + 1.5vw);
  text-align: center;
}

.interactive {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

.interactiveHead {
  font-weight: 500;
  text-align: center;
  font-size: calc(4px + 2.5vw);
}

.interactiveContainer {
  display: grid;
  grid-template-rows: 5vw auto;
  align-items: center;
  height: 80%;
}

.interactiveFrame {
  display: grid;
  align-items: center;
  height: 100%;
}

.nextBtn {
  background-color: #d3ede7;
  color: #034031;
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  font-size: 22px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 50%;
  border: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.nextBtn:hover {
  background-color: #bbdad2;
}

/* Menu */
.MenuItem {
  background-color: white;
  padding: 1vw;
  min-height: fit-content;
  height: fit-content;
  margin: 0.3vw 0;
  color: black;
  border-radius: 10px;
  display: grid;
  align-items: center;
}

.Menu {
  border: solid 1px #e1fef7;
  border-radius: 40px;
  padding: 20px;
  min-width: fit-content;
  width: 32%;
  height: 80%;
  max-height: 450px;
  display: grid;
  align-items: center;
}

.error {
  color: rgb(204, 5, 5);
  font-family: "DM Sans", -apple-system, "Helvetica Neue", sans-serif;
  text-align: center;
}

.submitButton {
  margin: 0;
  font-size: calc(10px + 1vw);
  height: calc(10px + 3vw);
}

.item {
  margin-left: -5px;
  font-size: calc(10px + 0.5vw);
}

.itemsContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  width: 90%;
  margin: 2vw;
}

/* Orders */
.OrderContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 2vw;
}

.OrderItem {
  background-color: #f3d5fe;
  color: black;
  font-family: "DM Mono", -apple-system, "Helvetica Neue", sans-serif;
  font-size: calc(10px + 0.5vw);
  width: calc(50px + 4.5vw);
  height: calc(70px + 4.5vw);
  text-align: center;
  padding: 1vw;
  margin: 0.75vw;

  /* center */
  display: grid;
  align-items: center;
}

.empty {
  background-color: none;
  border: 2px solid #f3d5fe;
  width: calc(50px + 4.5vw);
  height: calc(66px + 4.5vw);
  text-align: center;
  padding: 1vw;
  margin: 0.75vw;
}

.question {
  font-size: calc(40px + 1vw);
  color: #f3d5fe;
  margin-top: 30px;
}

@media (max-width: 850px) {
  .interactive {
    gap: 25px;
  }

  .submitButton {
    font-size: smaller;
  }
}

/* CHECKBOXES */

.container {
  display: block;
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  /* height: 20px;
  width: 20px; */
  height: 70%;
  width: 10%;
  border: 1.5px solid #bae7dc;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #bae7dc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #bae7dc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.container .checkmark::after {
  left: 0.25vw;
  top: -0.1vw;
  width: 0.5vw;
  height: 0.9vw;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark::after {
  display: block;
}

/* ENDING */

.smallTransition {
  padding: 0 10vw;
  display: grid;
  align-items: center;
}

.link {
  text-decoration: none;
}
